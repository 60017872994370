import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { styleToColor } from "@/game-tft/get-style-to-color.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import useMatchListStats from "@/game-tft/use-matchlist-stats.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { useLocalizedTraits } from "@/game-tft/use-traits.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ProfileOverviewStatsBlock = ({ account, statsType }) => {
  const fullName = `${account?.account?.game_name}-${account?.account?.tag_line}`;
  const {
    traits: statTraits,
    units: statUnits,
    count: totalCount,
  } = useMatchListStats(fullName);
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    settings: {
      tft: { isSkinsEnabled },
    },
  } = state;
  const selectedSet = useSetByParam();
  const champions = state.tft?.[selectedSet]?.champions;
  const traits =
    useLocalizedTraits(selectedSet) || state.tft?.[selectedSet]?.traits;

  const skinSetting = isSkinsEnabled;

  const blockTitles = {
    traits: t("tft:common.traits", "Traits"),
    units: t("tft:common.units", "Units"),
    fallback: "Stats",
  };

  const title = blockTitles[statsType] || blockTitles.fallback;

  let selectedStats = [];
  switch (statsType) {
    case "units": {
      selectedStats = filterByLatest(statUnits, champions);
      break;
    }
    case "traits": {
      selectedStats = filterByLatest(statTraits, traits);
      break;
    }
  }
  if (!selectedStats.length) {
    return <Card style={{ height: 390 }} />;
  }

  return (
    <ListItemsTruncated
      title={title}
      list={selectedStats}
      itemLeftContent={(item) => {
        if (statsType === "units") {
          const { name } = item;
          return (
            <>
              <ChampionImage
                champKey={name}
                cost={champions?.[name]?.cost}
                size={40}
                set={selectedSet}
                skinSetting={skinSetting}
              />
              <div className="type-callout--bold">
                {champions?.[name]?.name}
              </div>
            </>
          );
        }
        const traitName = item.name;
        const traitMetColor = styleToColor(
          StaticTFT.getTraitBonus(traitName, selectedSet, item.avgTier)?.style,
        );
        const correctedTraitName = StaticTFT.getTraitKey(
          traitName,
          selectedSet,
        );

        if (!correctedTraitName) return null;

        return (
          <>
            <TypeIcon
              size={40}
              name={correctedTraitName}
              traitMetColor={traitMetColor}
            />
            <div className="type-callout--bold">
              {traits?.[traitName]?.name}
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const games = item?.count;
        const avg = item?.avgPlacement?.toFixed(1);
        const percent = (games / (totalCount || 1)).toLocaleString(
          getLocale(),
          {
            style: "percent",
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          },
        );

        const numberSign = "#";
        const leftBracket = "(";
        const rightBracket = ")";

        return (
          <>
            <div className="type-caption--bold">
              {numberSign}
              {avg}
              {t(" Avg.")}
            </div>
            <div className="type-caption" style={{ color: "var(--shade2)" }}>
              {t("lol:itemsets.games", `${games} Games`, {
                games: games,
              })}{" "}
              {leftBracket}
              {percent}
              {rightBracket}
            </div>
          </>
        );
      }}
    />
  );
};

let latestSet = 0;
function filterByLatest(array, entities) {
  if (!latestSet) {
    for (const { name } of array) {
      const num = Number(name.match(/TFT(\d+)_/)?.[1]);
      if (Object.keys(entities).includes(name) && num > latestSet) {
        latestSet = num;
      }
    }
  }
  return array.filter(({ name }) => {
    return name.startsWith(`TFT${latestSet}`);
  });
}

export default ProfileOverviewStatsBlock;
